var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Sửa khoản hệ thống",
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataTransfer",
          attrs: {
            "label-width": "120px",
            model: _vm.dataTransfer,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên khoản", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên khoản" },
                model: {
                  value: _vm.dataTransfer.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataTransfer, "name", $$v)
                  },
                  expression: "dataTransfer.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Đơn vị", prop: "unit" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập đơn vị" },
                model: {
                  value: _vm.dataTransfer.unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataTransfer, "unit", $$v)
                  },
                  expression: "dataTransfer.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.dataTransfer.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataTransfer, "description", $$v)
                  },
                  expression: "dataTransfer.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("dataTransfer")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("dataTransfer")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }